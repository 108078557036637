import React from 'react';
import { createRoot } from 'react-dom/client';
import BotPreview from './integration/BotPreview'; 

window.initBotPreview = (containerId, botToken, theme, color, position) => {
  const container = document.getElementById(containerId);
  const root = createRoot(container); // Create a root.
  root.render(
    <BotPreview 
      bot={{ bot_id: botToken }}
      theme={theme} 
      color={color}
      position={position} 
    />
  );
};
